import { Component, OnChanges, OnInit, ViewEncapsulation, Input, Output, EventEmitter, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import ArcGISMap from "@arcgis/core/Map.js";
import MapView from "@arcgis/core/views/MapView.js";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Graphic from '@arcgis/core/Graphic'
import esriConfig from "@arcgis/core/config";
import ImageryTileLayer from "@arcgis/core/layers/ImageryTileLayer";
import ClassBreaksRenderer from '@arcgis/core/renderers/ClassBreaksRenderer';
import Locate from '@arcgis/core/widgets/Locate';
import Track from '@arcgis/core/widgets/Track';
import Geometry from "@arcgis/core/geometry/Geometry.js";
import Polyline from '@arcgis/core/geometry/Polyline';
import Fullscreen from "@arcgis/core/widgets/Fullscreen.js";
import Point from '@arcgis/core/geometry/Point';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer'
import VectorTileLayer from '@arcgis/core/layers/VectorTileLayer';
import Basemap from '@arcgis/core/Basemap'
import { LocationDisplay } from '../guru-api/models/site-section.model';
import SpatialReference from '@arcgis/core/geometry/SpatialReference'
import PictureMarkerSymbol from '@arcgis/core/symbols/PictureMarkerSymbol'
// import { NavStep } from '../guru-api/models/nav.model';
import * as reactiveUtils from '@arcgis/core/core/reactiveUtils';
// import { Preferences } from '@capacitor/preferences';

export interface SpotClick {
  isFullscreen: boolean
  ld: LocationDisplay
}
@Component({
  selector: 'app-arcmap',
  templateUrl: './arcmap.component.html',
  styleUrls: ['./arcmap.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ArcmapComponent implements OnInit {
  @ViewChild('mapContainer', { read: ElementRef, static: false }) elementView: ElementRef;
  @Input() points: LocationDisplay[] = null;
  // @Input() navPoints: NavStep[] = null;
  @Input() inNavRange: boolean = false;
  @Input() externalSelect: LocationDisplay = null
  map: ArcGISMap = null;
  mapView: MapView = null;
  pointGraphicsLayer: GraphicsLayer = null;
  @Output() pointClick: EventEmitter<SpotClick> = new EventEmitter();
  navGraphics: GraphicsLayer = null;
  trackerWidget: Track = null;
  fullscreenMode = false;
  imgLayer: ImageryTileLayer = null;
  navStop: HTMLElement = null;
  clearSelectionButton: HTMLElement = null;
  centerCoords: number[] = [-77.00771991, 38.8898128];
  firstClick: number = -1;
  graphicsMap: {} = {};
  selectedLD: LocationDisplay = null;
  selectedPoint: {
    img: any,
    point: any,
  } = null;
  defaultSymbolSize: {
    height: number,
    width: number,
  } = {
    height: 15,
    width: 15,
  }
  constructor() {
    esriConfig.apiKey = "AAPKa9c77f3e8e534c5097e00ce7143f9e0fGLbyBokNJNRI-v7THbwTSas2YvEOj1kNI3WveMoTKYlfBfNvuv7UlWESGgbwumKb"
    esriConfig.log.level = "info"
    // Preferences.get({
    //   key: "first-map-click"
    // }).then((val) => {
    //   console.log("Val:", val)
    //   if (!val.value) {
    //     this.firstClick = 0;
    //   } else {
    //     this.firstClick = parseInt(val.value);
    //   }
    // })
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.points) {
      this.loadPoints();
    }
    if (changes.externalSelect) {
      if (this.externalSelect == null) {
        this.handlePointClick(this.selectedLD)
      } else {
        this.handlePointClick(this.externalSelect)
      }
    }
    // if (changes.navPoints) {
    //   if (this.navPoints == null) {
    //     return
    //   }
    //   if (this.navGraphics) {
    //     this.map.remove(this.navGraphics)
    //   }
    //   if (this.trackerWidget) {
    //     this.mapView.ui.remove(this.trackerWidget)
    //   }
    //   if (this.navStop) {
    //     this.mapView.ui.remove(this.navStop)
    //   }
    //   let polyLine = {
    //     type: 'polyline',
    //     paths: [],
    //   }
    //   this.navPoints.forEach((np) => {
    //     polyLine.paths.push([np.Coordinates.Long, np.Coordinates.Lat])
    //   })
    //   const simpleLineSymbol = {
    //     type: "simple-line",
    //     color: [30, 126, 238], // Atmospheric Blue
    //     width: 2
    //   };
    //   this.navGraphics = new GraphicsLayer();
    //   const lineGraphic = new Polyline({
    //     hasM: false,
    //     hasZ: false,
    //     paths: polyLine.paths,
    //     // spatialReference: {wkid: 3857}
    //   })
    //   this.navGraphics.add(new Graphic({
    //     geometry: lineGraphic,
    //     symbol: simpleLineSymbol,
    //   }))
    //   this.map.add(this.navGraphics)
    //   // console.log(this.navPoints);
    //   if (this.inNavRange) {
    //     const symbolDef = {
    //       type: "simple-marker",
    //       size: "12px",
    //       color: "blue",
    //       outline: {
    //         color: "#efefef",
    //         width: "1.5px"
    //       }
    //     }
    //     navigator.geolocation.getCurrentPosition = async function (success, error) {
    //       // console.log("getPos")
    //       const newPost = await Geolocation.getCurrentPosition()
    //       success(newPost);

    //     };

    //     this.trackerWidget = new Track({
    //       geolocationOptions: Geolocation,
    //       view: this.mapView,
    //       graphic: new Graphic({
    //         symbol: symbolDef

    //       }),
    //       useHeadingEnabled: false,
    //       goToLocationEnabled: false,
    //       //@ts-ignore
    //       // tracking: true,
    //     });
    //     this.mapView.ui.add(this.trackerWidget, "top-left");
    //     this.trackerWidget.start()
    //   }

    //   this.navStop = document.createElement('div');
    //   this.navStop.className = 'esri-widget--button esri-widget esri-interactive esri-icon-error'
    //   this.mapView.ui.add(this.navStop, "top-left");
    //   this.navStop.addEventListener('click', () => {
    //     this.map.remove(this.navGraphics);
    //     if (this.inNavRange) {
    //       this.trackerWidget.stop();
    //       this.mapView.ui.remove(this.trackerWidget);
    //     }

    //     this.mapView.ui.remove(this.navStop)
    //     this.mapView.goTo({
    //       center: this.centerCoords
    //     })
    //   })
    // }

  }
  ngOnInit(): void {
    this.map = new ArcGISMap({
      // basemap: "arcgis-topographic"
      // basemap: basemap
    });
    this.mapView = new MapView({
      map: this.map,
      container: "mapbox",
      constraints: {
        snapToZoom: false
      },
       


      // center:[-89.42231, 13.88427],
      center: this.centerCoords,
      zoom: .6,
      rotation: 90
    });
    // const rootBox = document.getElementsByClassName("esri-view-root")[0] as HTMLElement
    // rootBox.style.backgroundColor = "#f1f3f0"

    // const fullscreen = new Fullscreen({
    //   view: this.mapView
    // });
    // this.mapView.ui.add(fullscreen, "top-right");

    var element = document.createElement('div');
    const baseClass = 'esri-widget--button esri-widget esri-interactive '
    element.className = baseClass + "esri-icon-zoom-out-fixed";

    element.addEventListener('click', (evt) => {
      this.fullscreenMode = !this.fullscreenMode;
      if (this.fullscreenMode) {
        element.className = element.className.replace("out-fixed", "in-fixed")
      } else {
        element.className = element.className.replace("in-fixed", "out-fixed")
      }
      // this.generateImageLayer();

      // this.mapView.
      // this.mapView.goTo(this.imgLayer.fullExtent)
      // this.mapView.goTo({
      //   zoom: 2,
      // })

    })
    this.mapView.ui.add(element, "top-right");

    var centerButton = document.createElement('div')
    centerButton.className = baseClass + "esri-icon-locate"
    centerButton.addEventListener('click', (evt) => {
           this.mapView.goTo({
            center: this.centerCoords,
            zoom: .8
          })
    })
    this.mapView.ui.add(centerButton, "top-right");


    // var recenterElement = document.createElement('div')
    // recenterElement.className = 'esri-widget--button esri-widget esri-interactive esri-icon-zoom-to-object'
    // recenterElement.addEventListener('click', () => {
    //   this.mapView.goTo(this.imgLayer);
    // })
    // this.mapView.ui.add(recenterElement, 'top-left')
    // navigator.geolocation.getCurrentPosition = async function (success, error) {
    //   // console.log("getPos")
    //   const newPost = await Geolocation.getCurrentPosition()
    //   success(newPost);

    // };
    // reactiveUtils.when(() => this.mapView.resizing == true, () => {
    //   console.log(this.mapView)
    // })
    this.generateImageLayer()
    // const locateBtn = new Locate({
    // //  geolocationOptions: 
    //   view: this.mapView,
    // });

    // // Add the locate widget to the top left corner of the view
    // this.mapView.ui.add(locateBtn, {
    //   position: "top-left",
    // });
    // renderer.addClassBreakInfo({
    //   minValue: -999,
    //   maxValue: -1,
    //   symbol:{
    //     type:"simple-fill",
    //     color:"#FFFFFF"
    //   }
    // })

    // (async () => {


    this.mapView.on("click", (event) => {
      const opts = {
        include: this.pointGraphicsLayer
      }
      this.mapView.hitTest(event, opts).then((response) => {
        if (response.results?.length) {
          const mp = response.results[0].mapPoint;
          // console.log(mp)
          const targetLat = mp.latitude;
          const targetLong = mp.longitude;
          console.log("clickpoint", targetLat.toFixed(5), targetLong.toFixed(5))
          const res = this.points.find((ld: LocationDisplay) => {
            // console.log(ld.latitude.toFixed(5), ld.longitude.toFixed(5))
            return Math.abs(ld.latitude - targetLat) < .0001 && Math.abs(ld.longitude - targetLong) < .0001
          })
          console.log(res)
          if (res) {
            this.handlePointClick(res)
            console.log("returned")
          }
        }
      })
    })
    // })()

    
  }
  clearClickedPoint() {
    console.log(this.selectedLD)
    const existingPointDef = this.graphicsMap[this.selectedLD.id]
    this.pointGraphicsLayer.remove(existingPointDef.point)
    this.pointGraphicsLayer.remove(existingPointDef.img)
    existingPointDef.img.symbol.height = "20px"
    existingPointDef.img.symbol.width = "20px"
    existingPointDef.point.symbol.color = [255, 255, 255]
    console.log(existingPointDef)
    setTimeout(() => {
      this.pointGraphicsLayer.add(existingPointDef.img)
    this.pointGraphicsLayer.add(existingPointDef.point)
    }, 1)
    this.removeClearSelectionButton()
    this.graphicsMap[this.selectedLD.id] = existingPointDef

      console.log("cleared: ", this.graphicsMap[this.selectedLD.id])
      
        this.selectedLD = null
  }
  
  handlePointClick(res: LocationDisplay) {
    console.log("handle click for: ", res)
    var wasPreviouslySelected = false
   
    if (this.selectedLD) {
      if (res != null) {
        wasPreviouslySelected = this.selectedLD.id == res.id
      }
      if (wasPreviouslySelected) {
        return
      }
      this.clearClickedPoint()
      
    }
    this.pointClick.emit({
      isFullscreen: this.fullscreenMode,
      ld: res,
    })
    // if (wasPreviouslySelected) {
    //   console.log("was already selected and cleared")
    //   return
    // }
    if (res == null) {
      return
    }
    const pointDef = this.graphicsMap[res.id]
    this.pointGraphicsLayer.remove(pointDef.point)
    this.pointGraphicsLayer.remove(pointDef.img)
    pointDef.img.symbol.height = "30px"
    pointDef.img.symbol.width = "30px"
    pointDef.point.symbol.color = [30, 126, 238]
    setTimeout(() => {
      this.pointGraphicsLayer.add(pointDef.img)
    this.pointGraphicsLayer.add(pointDef.point)
    }, 1)
    // console.log(this.graphicsMap[res.id])
    this.selectedLD = res
    this.addClearSelectionButton()

      // const newPoint = this.graphicsMap[res.id]
      // const pointGraphic = newPoint.point;
      // const imgGraphic = newPoint.img
      // this.pointGraphicsLayer.remove(pointGraphic)
      // this.pointGraphicsLayer.remove(imgGraphic)
      // imgGraphic.symbol.height = "30px"
      // imgGraphic.symbol.width = "30px"
      // pointGraphic.symbol.color = [30, 126, 238];
      // console.log("graphic size: ", imgGraphic.symbol)
      // this.selectedPoint = newPoint
      // this.selectedPoint.img = imgGraphic
      // this.selectedPoint.point = pointGraphic
      //   this.pointGraphicsLayer.add(imgGraphic)
      //   this.pointGraphicsLayer.add(pointGraphic)


     
    

  }
  updateMapLayer(lx: __esri.Layer) {
    this.map.remove(lx)
    this.map.add(lx)
  }
  generateImageLayer() {
    if (this.imgLayer) {
      this.map.remove(this.imgLayer);
    }
    this.imgLayer = new ImageryTileLayer({
      // url: 'https://ss6imagery.arcgisonline.com/imagery_sample/landsat8/Bolivia_LC08_L1TP_001069_20190719_MS.tiff',
      // url: "https://ddypw7b3n6dsm.cloudfront.net/sch/SCH-Full-1_modified-cog.tif", // WORKS
      url: "https://ddypw7b3n6dsm.cloudfront.net/cvc/CVC_modified-cog.tif", // WORKS
      // minScale: 5,
      // maxScale: 0,
      // url: "https://ddypw7b3n6dsm.cloudfront.net/sch/SCH-Full-500DPI_modified-cog.tif",
      // bandIds: [3,2,1],
      interpolation: "nearest",
      // renderer: renderer

    });
    
    try {
      let err = false;
      this.map.add(this.imgLayer)

      this.mapView.whenLayerView(this.imgLayer).then(() => {
        // this.mapView.goTo(this.imgLayer);
        // this.mapView.goTo(this.centerCoords)
        // this.imgLayer.tileInfo.lods.forEach(lx => {
        //   lx.resolution = 0.04848102971783701
        //   lx.scale = 183.23538790993516
        // })
        // console.log(this.imgLayer)

        // if (this.points) {
        //   this.loadPoints();
        // }
      });
      // if (this.points) {
      //   // this.loadPoints();
      // }
    }

    catch (e) {
      console.log(e)
    }
  }
  loadPoints() {
    if (!this.points || this.points.length == 0) {
      return;
    }
    // let graphicsLayer = new GraphicsLayer();
    // if (!this.pointGraphicsLayer) {
    if (this.pointGraphicsLayer) {
      this.map.remove(this.pointGraphicsLayer)
      this.pointGraphicsLayer.destroy();
    }
    this.pointGraphicsLayer = new GraphicsLayer()
    // }
    this.map.add(this.pointGraphicsLayer);


    this.points.forEach((px) => {
      let np = new Point({
        // longitude: px.longitude,
        // latitude: px.latitude,
        x: -1,
        y: -1,
        z: -1,
        spatialReference: SpatialReference.WGS84
      });
      np.latitude = px.latitude;
      np.longitude = px.longitude
      let simpleMarkerSymbol = {
        type: "text",
        color: "white",
        outline: {
          color: [255, 255, 255], // White
          width: 1
        },
        text: px.id,

      };

      const newPoint = new Graphic({
        geometry: np,
        symbol: simpleMarkerSymbol,
      })
      const imgMarkerPoint = new Graphic({
        geometry: np,
        symbol: new PictureMarkerSymbol({
          url: '../../assets/img/location-filled.svg',
          width: "20px",
          height: "20px",
          yoffset: 2,

        })
      })
      this.pointGraphicsLayer.add(imgMarkerPoint);
      this.pointGraphicsLayer.add(newPoint);
      this.graphicsMap[px.id] = {
        "img": imgMarkerPoint,
        "point": newPoint
      }
    })


    // console.log(this.map.allLayers)
  }
  ngOnDestroy() {
    // console.log("on destroy")
    // this.map = null;
    // this.mapView = null;
  }
  setFirstClick() {
    this.firstClick = new Date().getMilliseconds();
    // Preferences.set({
    //   key: 'first-map-click',
    //   value: this.firstClick.toString(),
    // })
  }

  addClearSelectionButton() {
    this.clearSelectionButton = document.createElement('div');
    this.clearSelectionButton.className = 'esri-widget--button esri-widget esri-interactive clear-selection'
    this.clearSelectionButton.innerHTML = "Clear Selection"
    this.clearSelectionButton.addEventListener('click', () => {
      this.clearClickedPoint()
      this.pointClick.emit({
        isFullscreen: this.fullscreenMode,
        ld: null,
      })
    })
    this.mapView.ui.add(this.clearSelectionButton, "top-right");
  }
  removeClearSelectionButton() {
    this.mapView.ui.remove(this.clearSelectionButton)
  }

}
